// log the pageview with their URL
export const pageview = (url: URL) => {
  if (process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS) {
    window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
      page_path: url,
    });
  }
};

// log specific events happening.
export const event = (
  eventName: string,
  eventParams?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams | undefined,
) => {
  if (process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS) {
    window.gtag('event', eventName, eventParams);
  }
};

export const captureOutboundLink = (
  href: string,
  vendorSlug?: string,
  eventCallback?: () => void,
) => {
  if (process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS) {
    window.gtag('event', 'click', {
      event_category: 'outbound',
      event_label: href,
      transport_type: 'beacon',
      event_callback: eventCallback,
      dimension1: vendorSlug,
    });
  }
};

export function initialise() {
  if (!window.gtag) {
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).gtag = (...args: any[]) => {
      return (window as any).dataLayer.push(...args);
    };
  }
}
