import { Footer, FooterLink } from '@/components/footer/footer';
import { NavigationMenu2 } from '@/components/header/NavigationMenu';
import * as ga from '@/lib/ga';
import { createUrqlClient } from '@/lib/urql';
import '@/styles/globals.css';
import '@/styles/leaflet_override.css';
import 'leaflet/dist/leaflet.css';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Script from 'next/script';
import React, { useEffect, useRef } from 'react';
import Instagram from 'src/icons/instagram';
import { Client, Provider } from 'urql';
import { SSRExchange } from 'urql/node_modules/@urql/core/dist/types/exchanges/ssr';

if (process.env.NEXT_PUBLIC_API_MOCKING === 'true') {
  import('../mocks').then(({ setupMocks }) => {
    setupMocks();
  });
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  const { name, label, value, id } = metric;
  ga.event(name, {
    event_category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    event_label: id, // id unique to current page load
    non_interaction: true, // avoids affecting bounce rate.
  });
}

/**
 * Gets an instance of the URQL client. In the browser this
 * will always return the same instance so that results can be cached
 * as the interacts with the site. For SSR it will always return
 * a new instance so that results don't get cached. This could
 * return stale results and it also means the results of queries we do
 * inside getServerSideProps() may not be used since they could
 * be overriden by (old) results in the cache.
 * @returns URQL instance for rendering.
 */
function usePersistentUrqlClientInBrowser() {
  const persistentClient = useRef<{
    client: Client;
    ssrCache: SSRExchange;
  }>();

  const isServerSide = typeof window === 'undefined';
  if (isServerSide) {
    return createUrqlClient('readssrcache');
  }
  if (!persistentClient.current) {
    persistentClient.current = createUrqlClient('readssrcache');
  }
  return persistentClient.current;
}

function MyApp({ Component, pageProps }: AppProps) {
  const logoWidth = 300;
  const router = useRouter();
  const sticky = useRef<HTMLDivElement>(null);
  const stickyObserver = useRef<IntersectionObserver>();

  const { client, ssrCache } = usePersistentUrqlClientInBrowser();

  if (pageProps.urqlState) {
    ssrCache.restoreData(pageProps.urqlState);
  }
  useEffect(() => {
    if (!stickyObserver.current) {
      stickyObserver.current = new IntersectionObserver(
        ([e]) => e.target.classList.toggle('header-stuck', e.intersectionRatio < 1),
        { threshold: [1] },
      );
    }
    const stickyDiv = sticky.current;
    if (stickyDiv) {
      stickyObserver.current.observe(stickyDiv);
    }
    return () => {
      if (stickyObserver.current && stickyDiv) {
        stickyObserver.current.unobserve(stickyDiv);
      }
    };
  }, []);

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      ga.pageview(url);
    };
    // When the component is mounted, subscribe to router changes
    // and log those page views
    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <Provider value={client}>
      {process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
            strategy="afterInteractive"
          />
          <Script id="google-analytics" strategy="afterInteractive">
            {`
              gtag('js', new Date());
              gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}');
            `}
          </Script>
        </>
      )}
      <div className="flex flex-col min-h-screen">
        <div className="bg-wattle grid-cols-1 xl:hidden">
          <div className="text-center">
            <Link href="/" passHref>
              <a href="dummy">
                <Image
                  alt="Down South Weddings"
                  title="Down South Weddings - Your WA south west wedding directory"
                  src="/ver_assets/logo_full_v1.svg"
                  loading="eager"
                  layout="fixed"
                  width={logoWidth}
                  height={Math.round((logoWidth * 296) / 2084)}
                  priority
                />
              </a>
            </Link>
          </div>
        </div>

        <div
          ref={sticky}
          className="bg-wattle  grid grid-cols-1 -top-[1px] z-10 md:sticky xl:grid-cols-[320px_minmax(400px,_1fr)_320px]"
        >
          <div className="text-center hidden xl:block py-2">
            <Link href="/" passHref>
              <a href="dummy">
                <Image
                  alt="Down South Weddings"
                  title="Down South Weddings - Your WA south west wedding directory"
                  src="/ver_assets/logo_full_v1.svg"
                  loading="eager"
                  layout="fixed"
                  width={logoWidth}
                  height={Math.round((logoWidth * 296) / 2084)}
                  priority
                />
              </a>
            </Link>
          </div>
          <div className="">
            <NavigationMenu2 />
          </div>
          <div className="hidden xl:block" />
        </div>

        <Component {...pageProps} />
        <footer>
          <div className="bg-violet py-8 px-4">
            <div className="max-w-5xl mx-auto">
              <Footer />
            </div>
          </div>
          <div className="bg-paperdaisy pt-6 pb-20  md:pb-6 px-4">
            <div className="max-w-5xl mx-auto dsw-xs">
              Down South Weddings live and work in Perth (Boorloo) on unceded Whadjuk Noongar
              Country. We acknowledge and respect the Wardandi, Kaniyang, Bibbulman and Minang
              people as the Traditional Owners and Custodians of the land, sea and skies of the
              areas serviced by this directory.
            </div>
            <div className="mt-4 max-w-5xl mx-auto flex gap-10">
              <FooterLink href="https://www.instagram.com/downsouth_weddings/">
                <Instagram className="inline" /> Instagram
              </FooterLink>
              <FooterLink href="/privacy">Privacy Policy</FooterLink>
              <FooterLink href="/contact">Contact</FooterLink>
              <FooterLink href="/submissions">Submissions</FooterLink>

              {/* <FooterLink href="/terms">Terms &amp; Conditions</FooterLink> */}
            </div>
          </div>
        </footer>
      </div>
    </Provider>
  );
}

export default MyApp;
