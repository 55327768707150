import { AuthConfig, authExchange } from '@urql/exchange-auth';
import {
  cacheExchange,
  CombinedError,
  dedupExchange,
  errorExchange,
  fetchExchange,
  makeOperation,
  ssrExchange,
  createClient,
} from 'urql';

export function createUrqlClient(mode: 'readssrcache' | 'populatessrcache' = 'populatessrcache') {
  const isServerSide = typeof window === 'undefined';
  const ssrCache = ssrExchange({ isClient: mode === 'readssrcache' });
  const client = createClient({
    url: process.env.NEXT_PUBLIC_KEYSTONE_GQL_URL!,
    exchanges:
      isServerSide && mode === 'readssrcache'
        ? [dedupExchange, cacheExchange, ssrCache]
        : [dedupExchange, cacheExchange, ssrCache, fetchExchange],
  });
  return { client, ssrCache };
}

interface AuthState {
  ksApiUserId?: string;
  ksApitoken?: string;
}

const getAuth: AuthConfig<AuthState>['getAuth'] = async ({ authState }) => {
  if (!authState) {
    return {
      ksApiUserId: process.env.KS_API_USER_ID,
      ksApitoken: process.env.KS_API_TOKEN,
    };
  }

  return null;
};

const addAuthToOperation: AuthConfig<AuthState>['addAuthToOperation'] = ({
  authState,
  operation,
}) => {
  if (!authState || !authState.ksApiUserId || !authState.ksApitoken) {
    return operation;
  }

  const fetchOptions =
    typeof operation.context.fetchOptions === 'function'
      ? operation.context.fetchOptions()
      : operation.context.fetchOptions || {};

  return makeOperation(operation.kind, operation, {
    ...operation.context,
    fetchOptions: {
      ...fetchOptions,
      headers: {
        ...fetchOptions.headers,
        'x-ks-api-id': authState.ksApiUserId,
        'x-ks-api-token': authState.ksApitoken,
      },
    },
  });
};

const onError = ({ graphQLErrors, networkError }: CombinedError) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      return console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
    });

  if (networkError) console.log(`[Network error]: ${networkError}`);
};

const willAuthError: AuthConfig<AuthState>['willAuthError'] = ({ authState }) => {
  return !authState?.ksApiUserId || !authState?.ksApitoken;
};

export function getBackendAuthUrqlClient() {
  const client = createClient({
    url: process.env.NEXT_PUBLIC_KEYSTONE_GQL_URL!,
    exchanges: [
      dedupExchange,
      cacheExchange,
      authExchange({
        getAuth,
        addAuthToOperation,
        willAuthError,
      }),
      errorExchange({ onError }),
      fetchExchange,
    ],
  });
  return { client };
}
