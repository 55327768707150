import React, { forwardRef } from 'react';

type Props = { color?: string; size?: number; stroke?: number; className?: string };

const Bed = forwardRef(
  (
    { color = 'currentColor', size = 24, stroke = 1, ...rest }: Props,
    ref: React.LegacyRef<SVGSVGElement> | undefined,
  ) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-bed"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...rest}
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M3 7v11m0 -4h18m0 4v-8a2 2 0 0 0 -2 -2h-8v6" />
        <circle cx={7} cy={10} r={1} />
      </svg>
    );
  },
);

Bed.displayName = 'Bed';

export default Bed;
