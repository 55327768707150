import {
  useGetAccommodationCategoriesQuery,
  useGetRegionsQuery,
  useGetSupplierCategoriesQuery,
  useGetVenueCategoriesQuery,
} from '@/generated/graphql';
import Link from 'next/link';
import React, { ReactNode, useState, useEffect } from 'react';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import { RemoveScroll } from 'react-remove-scroll';
import Bed from 'src/icons/bed';
import BuildingCottage from 'src/icons/building-cottage';
import Confetti from 'src/icons/confetti';
import Macro from 'src/icons/macro';
import X from 'src/icons/x';
import {
  contentListItemLinkStyle,
  contentListStylesRecipe,
  menuRecipe,
  menuTriggerStyle,
  navigationMenuListStyle,
  navigationMenuStyle,
} from './NavigationMenu.css';

export const ContentListItem = React.forwardRef<
  HTMLAnchorElement,
  {
    href: string;
    onKeyDown: (e: React.KeyboardEvent<HTMLAnchorElement>) => void;
    tabIndex: number;
    role: string;
    children: ReactNode;
    onClick: () => void;
  }
>(({ children, ...props }, forwardedRef) => (
  <li>
    <Link href={props.href} passHref>
      <a
        ref={forwardedRef}
        onKeyDown={props.onKeyDown}
        tabIndex={props.tabIndex}
        role={props.role}
        href={props.href.toString()}
        className={contentListItemLinkStyle}
        onClick={props.onClick}
      >
        {children}
      </a>
    </Link>
  </li>
));

function useIsSmallScreen() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    setIsSmallScreen(!window.matchMedia('(min-width: 640px)').matches);
  }, []);

  return isSmallScreen;
}

function VenuesMenus() {
  const [getRegionsQueryResult] = useGetRegionsQuery();
  const regionsData = getRegionsQueryResult.data;
  const [getVenueCategoriesQueryResult] = useGetVenueCategoriesQuery();
  const venueCategoriesData = getVenueCategoriesQueryResult.data;

  const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(
    (regionsData?.regions?.length || 0) + (venueCategoriesData?.venueCategories?.length || 0) + 1,
  );

  const isSmallScreen = useIsSmallScreen();

  return (
    <div className="venues-menu">
      <button {...buttonProps} type="button" className={menuTriggerStyle}>
        <BuildingCottage />
        Venues
      </button>
      <div className={menuRecipe({ isOpen })} role="menu">
        <RemoveScroll enabled={isOpen && isSmallScreen} removeScrollBar={isSmallScreen}>
          <div className="overflow-y-auto max-h-screen p-4">
            <div className="flex py-2">
              <div className="flex-grow flex-shrink-0 dsw-h4 px-2 block">
                <Link href="/venues/all" passHref>
                  <a href="dummy" onClick={() => setIsOpen(false)}>
                    Venues
                  </a>
                </Link>
              </div>
              <div className="text-right sm:hidden">
                <button
                  type="button"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <X />
                </button>
              </div>
            </div>

            <div className={contentListStylesRecipe({ layout: 'one' })}>
              <ContentListItem
                {...itemProps[0]}
                href="/venues/all"
                onClick={() => setIsOpen(false)}
              >
                <span className="font-bold">Show all</span>
              </ContentListItem>
            </div>

            <div className={contentListStylesRecipe({ layout: 'two' })}>
              <div className="dsw-body-bold px-2 mt-2 mb-2 text-orchid">By regions</div>
              <div className="dsw-body-bold px-2 mt-2 mb-2 text-orchid">By categories</div>
            </div>

            <div className={contentListStylesRecipe({ layout: 'two' })}>
              <div className="regions-list">
                {regionsData?.regions?.map((region, idx) => (
                  <div key={region.slug}>
                    <ContentListItem
                      {...itemProps[idx + 1]}
                      href={`/venues/region/${region.slug}`}
                      onClick={() => setIsOpen(false)}
                    >
                      <span>{region.name}</span>
                      <div className="mb-2">
                        {region.suburbs && (
                          <div className="text-boronia text-xs">
                            {region.suburbs.map((suburb) => suburb.name).join(', ')}
                          </div>
                        )}
                      </div>
                    </ContentListItem>
                  </div>
                ))}
              </div>

              <div className="grid lg:grid-cols-2 auto-rows-min categories-list">
                {venueCategoriesData?.venueCategories?.map((category, idx) => (
                  <div key={category.slug}>
                    <ContentListItem
                      {...itemProps[idx + 1 + (regionsData?.regions?.length || 0)]}
                      href={`/venues/category/${category.slug}`}
                      onClick={() => setIsOpen(false)}
                    >
                      {category.name}
                    </ContentListItem>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </RemoveScroll>
      </div>
    </div>
  );
}

function SuppliersMenus() {
  const [getRegionsQueryResult] = useGetRegionsQuery();
  const regionsData = getRegionsQueryResult.data;
  const [getCategoriesQueryResult] = useGetSupplierCategoriesQuery();
  const supplierCategoriesData = getCategoriesQueryResult.data;

  const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(
    (regionsData?.regions?.length || 0) +
      (supplierCategoriesData?.supplierCategories?.length || 0) +
      1,
  );
  const isSmallScreen = useIsSmallScreen();

  return (
    <div className="suppliers-menu">
      <button {...buttonProps} type="button" className={menuTriggerStyle}>
        <Macro />
        Suppliers
      </button>
      <div className={menuRecipe({ isOpen })} role="menu">
        <RemoveScroll enabled={isOpen && isSmallScreen} removeScrollBar={isSmallScreen}>
          <div className="overflow-y-auto max-h-screen p-4">
            <div className="flex py-2">
              <div className="flex-grow flex-shrink-0 dsw-h4 px-2 block">
                <Link href="/suppliers/all" passHref>
                  <a href="dummy" onClick={() => setIsOpen(false)}>
                    Suppliers
                  </a>
                </Link>
              </div>
              <div className="text-right sm:hidden">
                <button
                  type="button"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <X />
                </button>
              </div>
            </div>

            <div className={contentListStylesRecipe({ layout: 'one' })}>
              <ContentListItem
                {...itemProps[0]}
                href="/suppliers/all"
                onClick={() => setIsOpen(false)}
              >
                <span className="font-bold">Show all</span>
              </ContentListItem>
            </div>

            <div className={contentListStylesRecipe({ layout: 'two' })}>
              <div className="dsw-body-bold px-2 mt-2 mb-2 text-orchid">By regions</div>
              <div className="dsw-body-bold px-2 mt-2 mb-2 text-orchid">By categories</div>
            </div>

            <div className={contentListStylesRecipe({ layout: 'two' })}>
              <div className="regions-list">
                {regionsData?.regions?.map((region, idx) => (
                  <div key={region.slug}>
                    <ContentListItem
                      {...itemProps[idx + 1]}
                      href={`/suppliers/region/${region.slug}`}
                      onClick={() => setIsOpen(false)}
                    >
                      {region.name}
                      <div className="mb-2">
                        {region.suburbs && (
                          <div className="text-boronia text-xs">
                            {region.suburbs.map((suburb) => suburb.name).join(', ')}
                          </div>
                        )}
                      </div>
                    </ContentListItem>
                  </div>
                ))}
              </div>
              <div className="grid lg:grid-cols-2 auto-rows-min categories-list">
                {supplierCategoriesData?.supplierCategories?.map((category, idx) => (
                  <div key={category.slug}>
                    <ContentListItem
                      {...itemProps[idx + 1 + (regionsData?.regions?.length || 0)]}
                      href={`/suppliers/category/${category.slug}`}
                      onClick={() => setIsOpen(false)}
                    >
                      {category.name}
                    </ContentListItem>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </RemoveScroll>
      </div>
    </div>
  );
}

function AccommodationsMenus() {
  const [getRegionsQueryResult] = useGetRegionsQuery();
  const regionsData = getRegionsQueryResult.data;
  const [getAccommodationsQueryResult] = useGetAccommodationCategoriesQuery();
  const accommodationCategoriesData = getAccommodationsQueryResult.data;

  const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(
    (regionsData?.regions?.length || 0) +
      (accommodationCategoriesData?.accommodationCategories?.length || 0) +
      1,
  );

  const isSmallScreen = useIsSmallScreen();

  return (
    <div className="stay-menu">
      <button {...buttonProps} type="button" className={menuTriggerStyle}>
        <Bed />
        Stay
      </button>
      <div className={menuRecipe({ isOpen })} role="menu">
        <RemoveScroll enabled={isOpen && isSmallScreen} removeScrollBar={isSmallScreen}>
          <div className="overflow-y-auto max-h-screen p-4">
            <div className="flex py-2">
              <div className="flex-grow flex-shrink-0 dsw-h4 px-2 block">
                <Link href="/accommodations/all" passHref>
                  <a href="dummy" onClick={() => setIsOpen(false)}>
                    Stay
                  </a>
                </Link>
              </div>
              <div className="text-right sm:hidden">
                <button
                  type="button"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <X />
                </button>
              </div>
            </div>

            <div className={contentListStylesRecipe({ layout: 'one' })}>
              <ContentListItem
                {...itemProps[0]}
                href="/accommodations/all"
                onClick={() => setIsOpen(false)}
              >
                <span className="font-bold">Show all</span>
              </ContentListItem>
            </div>

            <div className={contentListStylesRecipe({ layout: 'two' })}>
              <div className="dsw-body-bold px-2 mt-2 mb-2 text-orchid">By regions</div>
              <div className="dsw-body-bold px-2 mt-2 mb-2 text-orchid">By categories</div>
            </div>

            <div className={contentListStylesRecipe({ layout: 'two' })}>
              <div className="regions-list">
                {regionsData?.regions?.map((region, idx) => (
                  <div key={region.slug}>
                    <ContentListItem
                      {...itemProps[idx + 1]}
                      href={`/accommodations/region/${region.slug}`}
                      onClick={() => setIsOpen(false)}
                    >
                      {region.name}
                      <div className="mb-2">
                        {region.suburbs && (
                          <div className="text-boronia text-xs">
                            {region.suburbs.map((suburb) => suburb.name).join(', ')}
                          </div>
                        )}
                      </div>
                    </ContentListItem>
                  </div>
                ))}
              </div>
              <div className="grid lg:grid-cols-2 auto-rows-min categories-list">
                {accommodationCategoriesData?.accommodationCategories?.map((category, idx) => (
                  <div key={category.slug}>
                    <ContentListItem
                      {...itemProps[idx + 1 + (regionsData?.regions?.length || 0)]}
                      href={`/accommodations/category/${category.slug}`}
                      onClick={() => setIsOpen(false)}
                    >
                      {category.name}
                    </ContentListItem>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </RemoveScroll>
      </div>
    </div>
  );
}

export function NavigationMenu2() {
  return (
    <nav className={navigationMenuStyle}>
      <div className={navigationMenuListStyle}>
        <VenuesMenus />
        <SuppliersMenus />
        <AccommodationsMenus />
        <Link href="/inspiration" passHref>
          <a href="dummy" className={menuTriggerStyle}>
            <Confetti />
            Inspiration
          </a>
        </Link>
      </div>
    </nav>
  );
}
