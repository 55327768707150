import React, { ReactNode } from 'react';
import Link from 'next/link';
import { UrlObject } from 'url';

type FooterLinkProps = {
  href: string | UrlObject;
  children: ReactNode;
};

export function FooterLink({ href, children }: FooterLinkProps) {
  return (
    <Link href={href} passHref>
      <a href="dummy" className="block dsw-xs leading-6 hover:underline">
        {children}
      </a>
    </Link>
  );
}

export function Footer() {
  return (
    <div className="grid gap-4 md:gap-0 grid-1 md:grid-cols-[2fr_1fr_1fr]">
      <div>
        <h5 className="text-boronia">Popular</h5>
        <FooterLink href="/venues/all">Wedding Venues South West WA</FooterLink>
        <FooterLink href="/venues/region/margaret-river">
          Margaret River Wedding Venues South West WA
        </FooterLink>
        <FooterLink href="/venues/category/private-property">
          Private Property Wedding Venues South West WA
        </FooterLink>
        <FooterLink href="/venues/category/beach">Beach Wedding Venues South West WA</FooterLink>
        <FooterLink href="/venues/category/barn">Barn Wedding Venues South West WA</FooterLink>
        <FooterLink href="/venues/category/forest">Forest Wedding Venues South West WA</FooterLink>
        <FooterLink href="/suppliers/category/photo-video">
          Wedding Photographers South West WA
        </FooterLink>
      </div>
      <div>
        <h5 className="text-boronia">List your business</h5>
        <FooterLink href="/join/free">Free listings</FooterLink>
        <FooterLink href="/join/premium">Premium listings</FooterLink>
        <FooterLink href="/join/info">Info for vendors</FooterLink>
      </div>
    </div>
  );
}
