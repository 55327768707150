import React, { forwardRef } from 'react';

type Props = { color?: string; size?: number; stroke?: number; className?: string };

const X = forwardRef(
  (
    { color = 'currentColor', size = 24, stroke = 1, ...rest }: Props,
    ref: React.LegacyRef<SVGSVGElement> | undefined,
  ) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-x"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...rest}
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <line x1={18} y1={6} x2={6} y2={18} />
        <line x1={6} y1={6} x2={18} y2={18} />
      </svg>
    );
  },
);

X.displayName = 'X';

export default X;
